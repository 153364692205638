<script>
import api from '@/api'
import CardMixin from '@/mixins/CardMixin'

export default {
  mixins: [CardMixin],
  props: {
    chunks: Array,
    section: Object
  },
  data() {
    return {
      formData: {
        text: ''
      }
    }
  },
  methods: {
    onShow() {
      this.formData.text = this.chunks.map(x => x.text.strip(' ')).join(' ')
    },
    async apiCall() {
      return api.post(`/corpus/chunks/replacement`, {
            old_chunk_list: this.chunks.map((x) => x.id),
            new_chunk_list: [
              {
                ...this.chunks[0],
                text: this.formData.text,
                kind: 'joined',
                is_excluded: false
              }
            ]
          }
      )
          .then(() => {

            this.$bvToast.toast(
                'Чанки объединены', { ...this.commonToaster, variant: 'success' }
            )

            this.$bvModal.hide('modal-join-chunks')
            console.debug('emit success')
            this.$emit('on-success')

          })
          .catch(error => {
            console.log(error)
            this.$bvToast.toast('Ошибка при объединении чанков', {
              ...this.commonToaster, variant: 'danger'
            })
          })
    },
    clear() {
      this.formData.text = ''
    }
  },
  computed: {
    majorChunk: {
      get() {
        return this.chunks[0]
      }
    },
    stateText() {
      return this.formData.text.length >= 10
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-join-chunks"
      :busy="!(stateText) || loading"
      centered lazy no-close-on-backdrop
      ok-only ok-title="Сохранить" scrollable
      size="lg"
      static
      title="Объединение чанков"
      @close="clear"
      @show="onShow"
      @ok.prevent="save"
  >
    <label for="modal-text-formData">Текст итогового чанка</label>
    <b-textarea id="modal-text-formData" v-model="formData.text" :state="stateText" class="mb-3" rows="8" trim/>
    <label for="modal-link-formData">Исходные чанки</label>
    <b-card
        v-for="chunk in this.chunks"
        :key="`deleted-chunk-${chunk.id}`"
        bg-variant="light"
        class="mb-1 pa-0 h6"
    >
      {{ chunk.text }}
    </b-card>

  </b-modal>
</template>

<style scoped>
.card-body {
  padding: 0.5rem;
}
</style>