<!--suppress JSUnresolvedReference -->
<script>
import Vue from 'vue'
import api from '@/api'
import { mapGetters } from 'vuex'

import CorpusMixin from '@/mixins/CorpusMixin'
import CorpusContentMixin from '@/mixins/CorpusContentMixin'
import DocumentMixin from '@/mixins/DocumentMixin'
import LBtn from '@/components/LBtn.vue'
import LDropBtn from '@/components/LDropBtn.vue'

import ModalEditSection from './modals/EditSection.vue'
import ModalHeadings from './modals/GenerateHeadings.vue'
import ModalGenerateChunks from './modals/GenerateChunks.vue'
import ModalGenerator from './modals/GenerateQuestions.vue'
import ModalEditChunk from './modals/EditChunk.vue'
import ChunkQuestions from './modals/ChunkQuestions.vue'
import ModalDivideChunk from './modals/DivideChunk.vue'
import ModalJoinChunks from './modals/JoinChunks.vue'
import ModalBgTasks from './modals/Tasks.vue'
import ChunkText from '@/pages/Corpus/ChunkText.vue'


export default {
  name: 'CorpusChunks',
  mixins: [CorpusMixin, CorpusContentMixin, DocumentMixin],
  components: {
    ChunkText,
    LDropBtn,
    LBtn,
    ModalEditSection,
    ModalHeadings,
    ModalGenerateChunks,
    ModalGenerator,
    ModalEditChunk,
    ChunkQuestions,
    ModalDivideChunk,
    ModalJoinChunks,
    ModalBgTasks
  },
  data() {
    return {
      selectedChunks: {},
      selectedSections: [],
      selectedSource: null,

      chunksStats: {},
      showExcluded: true,

      edit: {},
      notificationShown: {
        outerChanges: false,
        tooLong: false
      },

      timer: '',
      tasks: {
        modalType: 'generator',
        generator: [],
        export: []
      }
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION', 'CHUNK_ICONS', 'USER']),
    viewerVisibleSources() {
      if (!this.selectedSource) {
        return this.corpus.data.sources
      }
      return [this.selectedSource]
    },
    selectedChunksAsList() {
      const initialValue = []
      return Object.keys(this.selectedChunks).reduce((accumulator, currentKey) => {
        return accumulator.concat(this.selectedChunks[currentKey])
      }, initialValue)
    },
    chunkButtons() {
      let numQuiz = 0
      let numQuizDone = 0
      let numQa = 0
      this.selectedChunksAsList.forEach(chunk => {
        numQuiz += this.chunksStats[chunk.id]?.quiz_num ?? 0
        numQuizDone += this.chunksStats[chunk.id]?.quiz_done_num ?? 0
        numQa += this.chunksStats[chunk.id]?.question_answer_num ?? 0
      })
      const enableEdit = this.selectedChunksAsList.length === 1
      return {
        showQuestions: {
          // label: 'Вопросы',
          title: 'Показать карточку с вопросами и тестами чанка',
          variant: 'success',
          show: true,
          disabled: !enableEdit,
          click: () => this.$bvModal.show('modal-show-quest'),
          icon: 'clipboard-question'
        },
        editChunk: {
          // label: 'Редактировать',
          title: 'Редактировать чанк',
          variant: 'primary',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          disabled: !enableEdit,
          click: () => {
            Vue.set(this.edit, 'editChunk', this.selectedChunksAsList[0])
            this.$bvModal.show('modal-edit-chunk')
          },
          icon: 'pen'
        },
        moveChunk: {
          // label: 'Сдвинуть',
          title: 'Сдвинуть чанк',
          variant: 'primary',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          // выбрано меньше 2 чанков или выбраны чанки больше чем в 2 разделах
          disabled: !enableEdit,
          icon: 'arrows-up-down',
          children: [
            {
              label: 'Вверх',
              click: () => this.clickMoveChunk(-1),
              disabled: !(enableEdit && this.editedChunkIndex > 0)
            },
            {
              label: 'Вниз',
              click: () => this.clickMoveChunk(1),
              disabled: !(enableEdit && this.editedChunkIndex < this.editedChunkSection.chunks.length - 1)
            }
          ]
        },
        divideChunk: {
          // label: 'Разделить',
          title: 'Разделить чанк',
          variant: 'primary',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          disabled: !enableEdit,
          click: () => this.clickDivideChunk(),
          icon: 'arrows-split-up-and-left',
          iconClass: 'fa-rotate-180'
        },
        joinChunks: {
          // label: 'Объединить',
          title: 'Объединить выбранные чанки в один',
          variant: 'primary',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          // выбрано меньше 2 чанков или выбраны чанки больше чем в 2 разделах
          disabled: this.selectedChunksAsList.length < 2 || Object.keys(this.selectedChunks).length > 1,
          click: () => this.clickJoinChunk(),
          icon: 'arrows-to-circle'
        },
        markExcluded: {
          // label: 'Исключить',
          title: 'Отметить чанки исключенными',
          variant: 'danger',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          disabled: !this.selectedChunksAsList.length,
          children: [
            {
              label: 'Исключить',
              click: () => this.setChunkExcluded(true)
            },
            {
              label: 'Включить обратно',
              click: () => this.setChunkExcluded(false)
            }
          ],
          icon: 'strikethrough'
        },
        delete: {
          // label: 'Удалить',
          title: 'Удалить выбранное',
          variant: 'danger',
          show: (this.HAS_PERMISSION('corpus_section_edit') || this.HAS_PERMISSION('corpus_chunk_edit')),
          disabled: !(this.selectedSections.length + this.selectedChunksAsList.length),
          click: () => this.$bvModal.msgBoxConfirm(
              `Вы действительно хотите удалить выбранные разделы (${ this.selectedSections.length }) и чанки (${ this.selectedChunksAsList.length })?
              ${ numQuiz + numQa === 0 ? '' : `Это включает также сгенерированные вопросы и тесты (${ numQuiz + numQa })` }`,
              this.modalOptions
          ).then(confirmed => {
            if (confirmed) {
              this.deleteSelected()
            }
          }),
          icon: 'trash'
        },
        generateHeadings: {
          // label: 'Заголовки',
          title: 'Сгенерировать заголовки',
          variant: 'warning',
          show: this.HAS_PERMISSION('generators_bulk') && this.HAS_PERMISSION('corpus_chunk_edit'),
          disabled: !this.selectedChunksAsList.length,
          click: () => this.$bvModal.show('modal-generate-headings'),
          icon: 'tag'
        },
        generateChunks: {
          // label: 'Заголовки',
          title: 'Сгенерировать чанки',
          variant: 'warning',
          show: this.HAS_PERMISSION('corpus_chunk_edit'),
          disabled: !this.selectedChunksAsList.length || this.selectedSections.length > 1,
          click: () => this.$bvModal.show('modal-generate-chunks'),
          icon: 'microchip'
        },
        generateQuest: {
          // label: 'Генерировать',
          title: 'Сгенерировать вопросы или тесты',
          variant: 'warning',
          show: this.HAS_PERMISSION('generators_bulk') && this.HAS_PERMISSION('corpus_quest_edit'),
          disabled: !this.selectedChunksAsList.length,
          icon: 'clipboard-question',
          children: [
            {
              label: 'Тесты',
              click: () => this.clickShowGenerator('quiz')
            },
            {
              label: 'Вопросы',
              click: () => this.clickShowGenerator('question_answer')
            }
          ]
        },
        exportWord: {
          label: this.selectedChunksAsList.length > 0 ? `[${ numQuizDone }]` : undefined,
          title: `Выгрузить тесты выбранных чанков в документ (${ numQuizDone }).
В документ попадут только проверенные тесты из неисключенных чанков`,
          variant: 'success',
          show: true,
          disabled: !this.selectedChunksAsList.length,
          click: () => this.exportWord(),
          icon: 'file-word'
        }
      }
    },
    editedChunk() {
      if (this.selectedChunksAsList.length) {
        return this.selectedChunksAsList[0]
      }
    },
    editedChunkIndex() {
      if (this.editedChunk) {
        return this.editedChunkSection.chunks.indexOf(this.editedChunk)
      }
    },
    editedChunkSection() {
      if (this.editedChunk) {
        return this.selectedSource.sections[Object.keys(this.selectedChunks)[0]]
      }
    },

    tasksButtons() {
      return {
        generator: {
          label: 'Генератор',
          title: 'Показать фоновые задачи генератора',
          show: this.HAS_PERMISSION('generators_bulk'),
          click: () => this.showTasks('generator')
        },
        export: {
          label: 'Выгрузка',
          title: 'Показать фоновые задачи выгрузки',
          click: () => this.showTasks('export')
        }
      }
    },

    processingChunks() {
      return this.tasks.generator.filter(x => x.status === 'queue').map(x => x.chunk_id)
    }
  },
  mounted() {
    this.loadCardData()
        .then(() => {
          this.selectedSource = this.corpus.data.sources[0]
          this.ping()
          this.timer = setInterval(this.ping, process.env.VUE_APP_REFRESH_INTERVAL_SECONDS * 1000);
        })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    corpusId() {
      this.loadCardData()
    }
  },
  methods: {
    async loadCardData() {
      await this.loadCorpusById(true)
      await this.loadChunkStats()
    },

    async loadChunkStats() {
      if (!this.corpusId) {
        return
      }
      return api.get(`/corpus/${ this.corpusId }/chunk-stats`)
          .then(({ data }) => {
            this.chunksStats = data
          })
    },
    ping() {
      if (!this.corpusId) {
        clearInterval(this.timer)
        return
      }
      api.get(`/corpus/${ this.corpusId }/updates`, { params: { dateFrom: this.corpus.data.last_modified.date } })
          .then(({ data }) => {

            this.notificationShown.outerChanges = !!(data && data.length);
            const notMineUpdates = data.filter(({ user }) => user.id !== this.USER.id)
            const toastOpts = { variant: 'warning', title: 'Требуется обновление', noAutoHide: true, solid: true }
            if (notMineUpdates.length > 0 && !this.notificationShown.outerChanges) {
              this.$bvToast.toast('Другой пользователь внес изменения в этом корпусе, нажмите кнопку "Обновить"',
                  toastOpts
              )
            }

          })

      api.get(`/corpus/${ this.corpusId }/bg-tasks`)
          .then(({ data }) => this.tasks.generator = data)

      api.get(`/task`, { params: { code: 'export_word', entity_id: this.corpusId } })
          .then(({ data }) => this.tasks.export = data)
    },
    getElementId(source, section, chunk, question) {
      let prefix = 'source'
      const ids = [source.id]
      if (section) {
        prefix = 'section'
        ids.push(section.id)
      }
      if (chunk) {
        prefix = 'chunk'
        ids.push(chunk.id)
      }
      if (question) {
        prefix = 'question'
        ids.push(question.id)
      }
      return `${ prefix }-${ ids.join('-') }`
    },

    async reload() {
      console.debug('reload')
      this.loadCardData().then(() => {
        if (this.selectedSource) {
          this.selectedSource = this.corpus.data.sources.filter(source => source.id === this.selectedSource.id)[0]
        }
        this.notificationShown.outerChanges = false
        this.notificationShown.tooLong = false
      })
      await this.loadChunkStats()

      this.eraseSelection()
    },

    selectSection(sectionIndex, bulkCheck) {
      const section = this.selectedSource.sections[sectionIndex]

      if (this.isSectionSelected(sectionIndex)) {
        this.selectedSections.splice(this.selectedSections.indexOf(sectionIndex), 1)
      }
      else {
        this.selectedSections.push(sectionIndex)

        for (const chunk of section.chunks) {
          this.selectChunk(sectionIndex, chunk, true)
        }

        if (!bulkCheck && section.level > 0 && section.level < this.selectedSource.sections[sectionIndex + 1]?.level) {
          this.selectSubsections(sectionIndex + 1)
        }
      }

    },
    selectSubsections(parentIndex) {
      const curLevel = this.selectedSource.sections[parentIndex].level
      // console.log(parentIndex, curLevel)
      for (const [index, section] of this.selectedSource.sections.entries()) {
        if (index < parentIndex) continue
        // console.log(index, section.level)
        if (curLevel > section.level) break

        this.selectSection(index, this.selectedSource, true)
      }
    },
    selectChunk(sectionIndex, chunk, onlySelect) {
      if (!(sectionIndex in this.selectedChunks)) {
        Vue.set(this.selectedChunks, sectionIndex, [])
      }

      if (!this.isChunkSelected(sectionIndex, chunk)) {
        this.selectedChunks[sectionIndex].push(chunk)
        this.selectedChunks[sectionIndex].sort((a, b) => a.number - b.number)
      }
      else if (!onlySelect) {
        this.unselectChunk(sectionIndex, chunk)
      }
    },
    unselectChunk(sectionIndex, chunk) {
      this.selectedChunks[sectionIndex].splice(this.selectedChunks[sectionIndex].indexOf(chunk), 1)
      if (!this.selectedChunks[sectionIndex].length) {
        delete this.selectedChunks[sectionIndex]
      }
    },
    unselectExcluded() {
      for (const sectionIndex in this.selectedChunks) {
        this.selectedChunks[sectionIndex] = this.selectedChunks[sectionIndex].filter(x => !x.is_excluded)
      }
    },
    isSectionSelected(sectionIndex) {
      return this.selectedSections.includes(sectionIndex)
    },
    isChunkSelected(sectionIndex, chunk) {
      return this.selectedChunks[sectionIndex] && this.selectedChunks[sectionIndex].includes(chunk)
    },

    eraseSelection() {
      this.selectedSections = []
      this.selectedChunks = {}
    },

    clickEditSection(section) {
      Vue.set(this.edit, 'editSection', section)
      this.$bvModal.show('modal-edit-section')
    },

    clickEditChunk(chunk, section) {
      Vue.set(this.edit, 'editChunk', chunk)
      Vue.set(this.edit, 'section', section)
      this.$bvModal.show('modal-edit-chunk')
    },

    clickMoveChunk(numberIncrement) {
      this.$bvModal.msgBoxConfirm(
          `Сдвинуть чанк ${ numberIncrement < 0 ? 'вверх' : 'вниз' }?`,
          this.modalOptions
      ).then(confirmed => {
        if (confirmed) {
          this.moveChunk(numberIncrement)
        }
      })
    },

    async moveChunk(number_increment) {
      api.post(`/corpus/chunk/${ this.editedChunk.id }/move`, { number_increment })
          .then(() => {
            const currentChunkIndex = this.editedChunkSection.chunks.indexOf(this.editedChunk)
            this.editedChunkSection.chunks[currentChunkIndex + number_increment].number = this.editedChunk.number
            this.editedChunk.number = this.editedChunk.number + number_increment

            this.swapElements(this.editedChunkSection.chunks, currentChunkIndex, currentChunkIndex + number_increment)
            this.$bvToast.toast('Чанк успешно сдвинут. Подождите пока браузер обработает это изменение', {
              ...this.commonToaster, variant: 'success'
            })
          })
          .catch(({ response }) => {
            if (response.handled) return

            if (response.status === 423) {
              return this.$bvToast.toast(
                  'Нельзя сдвинуть чанк в эту сторону. Нажмите кнопку Обновить, чтобы увидеть актуальное состояние',
                  { ...this.commonToaster, variant: 'danger' }
              )
            }
          })
    },

    async deleteSelected() {
      api.post(`/corpus/source/${ this.selectedSource.id }/delete-items`, {
        sections: this.selectedSections.reduce((o, sectionIndex) =>
                Object.assign(o, {
                  [sectionIndex]: this.selectedSource.sections[sectionIndex].id
                }),
            {}),
        chunks: this.selectedChunks
      })
          .then(() => {
            for (const sectionIndex of this.selectedSections.sort((a, b) => a - b).reverse()) {
              this.selectedSource.sections.splice(sectionIndex, 1)
              delete this.selectedChunks[sectionIndex]
            }
            for (const sectionIndex in this.selectedChunks) {
              let section = this.selectedSource.sections[sectionIndex]
              if (!(section && section.chunks)) continue

              console.log(this.selectedChunks[sectionIndex])
              for (const chunk of this.selectedChunks[sectionIndex]) {
                let chunkIndex = section.chunks.indexOf(chunk)
                if (chunkIndex < 0) {
                  this.$bvToast.toast(`Что-то не так в чанке ${ chunk.text }`, {
                    ...this.commonToaster, variant: 'warning'
                  })
                }
                this.unselectChunk(sectionIndex, chunk)
              }
            }

            this.$bvToast.toast('Выбранные блоки успешно удалены', {
              ...this.commonToaster, variant: 'success'
            })
            this.eraseSelection()
          })
          .catch((error) => {
            console.log(error)
          })
    },

    clickDivideChunk() {
      this.$bvModal.show('modal-divide-chunk')
    },

    clickJoinChunk() {
      this.$bvModal.show('modal-join-chunks')
    },

    clickShowGenerator(questType) {
      Vue.set(this.edit, 'questType', questType)
      this.$bvModal.show('modal-generator')
    },

    getTaskStats(type) {
      const res = {}
      if (this.tasks[type]) {
        this.tasks[type].forEach(task => {
          res[task.status] = (res[task.status] || 0) + 1
        })
      }

      return res
    },

    getChunkStat(chunk, type) {
      const stat = this.chunksStats[chunk.id]
      if (stat) {
        return stat[`${ type }_done_num`] + '/' + stat[`${ type }_num`]
      }
      return '...'
    },

    async setChunkExcluded(value) {
      api.post(
          `/corpus/chunk/exclude`,
          this.selectedChunksAsList.map(x => x.id),
          { params: { set: value } }
      )
          .then(() => {
            this.selectedChunksAsList.forEach(x => x.is_excluded = value)
            this.$bvToast.toast(
                value ? 'Выбранные чанки исключены' : 'Исключение выбранных чанков отменено',
                { ...this.commonToaster, variant: 'success' }
            )
          })
    },

    exportWord() {
      const chunkList = this.selectedChunksAsList.map(x => x.id)
      api({
        url: `/corpus/${ this.corpusId }/chunk/exportWord`,
        method: 'post',
        responseType: 'blob',
        data: chunkList
      })
          .then(() => {
            this.$bvToast.toast('Выгрузка запланирована', { ...this.commonToaster, variant: 'success' })
          })
    },

    showTasks(type) {
      this.tasks.modalType = type
      this.$bvModal.show('modal-bg-tasks')
    }

  }
}
</script>

<template>
  <b-container id="chunks-viewer" class="p-0" fluid>
    <template v-if="selectedSource">
      <modal-edit-section :edit="edit"/>

      <modal-generate-chunks
          v-if="Object.keys(selectedChunks).length === 1"
          :section="selectedSource.sections[Object.keys(selectedChunks)[0]]"
          :selected-chunks="selectedChunksAsList"
          :source="selectedSource"
          @reload="reload"
          @unselect-excluded="unselectExcluded"
          @erase-selection="eraseSelection"
      />
      <modal-headings
          v-if="selectedChunksAsList.length > 0"
          :processing-chunks="processingChunks"
          :sections="selectedSource.sections"
          :selected-chunks="selectedChunks"
          :selected-chunks-num="selectedChunksAsList.length"
          @unselect-excluded="unselectExcluded"
          @on-ok="ping"
      />
      <modal-generator
          v-if="selectedChunksAsList.length > 0"
          :corpus="{name: corpus.data.name, id: corpus.data.id}"
          :docId="selectedSource.document_id"
          :processing-chunks="processingChunks"
          :question-type="edit.questType"
          :sections="selectedSource.sections"
          :selected-chunks="selectedChunksAsList"
          :selected-chunks-object="selectedChunks"
          :speciality="corpus.data.speciality_data.name"
          @on-ok="ping"
          @unselect-excluded="unselectExcluded"
      />
      <modal-edit-chunk :edit="edit"/>
      <modal-divide-chunk
          v-if="editedChunk"
          :chunk="editedChunk"
          :section="editedChunkSection"
          @on-success="reload"
      />
      <modal-join-chunks
          :chunks="selectedChunksAsList"
          :section="editedChunkSection"
          @on-success="reload"
      />
      <chunk-questions v-if="editedChunk" :chunk="editedChunk"/>
      <modal-bg-tasks :tasks="tasks[tasks.modalType]"/>
    </template>

    <b-overlay :show="corpus.loading" rounded="sm">
      <!-- Просмотр данных в виде документа-->
      <b-row v-if="corpus.data.sources" no-gutters>
        <!-- Навигатор документа -->
        <b-col cols="3">
          <b-navbar
              v-b-scrollspy
              class="flex-column scroll-sidebar corpus-sticky"
          >
            <ul class="nav flex-column">
              <template v-for="source in viewerVisibleSources">
                <!-- Книжка в навигаторе -->
                <li class="nav-item nav-source">
                  <a :href="`#${getElementId(source)}`" class="nav-link">
                    {{ source.title }}
                  </a>
                </li>
                <!-- Разделы книжки в навигаторе -->
                <ul class="nav ml-2 flex-column">
                  <li
                      v-for="section in source.sections"
                      :key="`key-${getElementId(source, section)}`"
                      :class="`ml-${section.level > 0 ? (section.level -1) : 0} mr-1`"
                  >
                    <a :href="`#${getElementId(source, section)}`" class="nav-link" target="_self">
                      {{ section.number }} {{ section.title }}
                    </a>
                  </li>
                </ul>
              </template>
            </ul>
          </b-navbar>
        </b-col>
        <!-- Содержимое корпуса -->
        <b-col class="pl-2" cols="7" xl="6" xxl="6">
          <div id="nav-scroller" ref="content" class="corpus-sticky">
            <!-- Итерация по источникам -->
            <div v-for="source in viewerVisibleSources" class="viewer-source">
              <!-- Название книжки -->
              <h4 :id="getElementId(source)">{{ source.title }}</h4>
              <!-- Итерация по разделам -->
              <div v-for="(section, sectionIndex) in source.sections" class="viewer-section">
                <h5 :id="getElementId(source, section)" class="mt-4 d-flex justify-content-between align-items-center ">
                  <!-- Название раздела с номером -->
                  <span>
                    <font-awesome-icon
                        v-if="selectedSource && HAS_PERMISSION('corpus_section_edit')"
                        :icon="['far', isSectionSelected(sectionIndex) ? 'square-check' : 'square']"
                        class="btn-icon"
                        @click="selectSection(sectionIndex)"
                    />
                    {{ section.number }} {{ section.title }}
                  </span>
                  <b-btn-group
                      v-if="selectedSource && (HAS_PERMISSION('corpus_section_edit') || HAS_PERMISSION('corpus_chunk_edit'))"
                      size="sm"
                  >
                    <l-btn
                        v-if="HAS_PERMISSION('corpus_section_edit')" icon="pen"
                        title="Редактировать"
                        variant="outline-secondary"
                        @click="clickEditSection(section)"
                    />
                    <l-btn
                        v-if="section.chunks.length === 0 && HAS_PERMISSION('corpus_chunk_edit')" icon="plus"
                        title="Новый чанк в разделе"
                        variant="outline-secondary"
                        @click="clickEditChunk({}, section)"
                    />
                  </b-btn-group>
                </h5>
                <!-- Итерация по чанкам -->
                <div
                    v-for="chunk in section.chunks"
                    v-show="!(!showExcluded && chunk.is_excluded)"
                    :id="getElementId(source, section, chunk)"
                    :class="{
                      selected: isChunkSelected(sectionIndex, chunk),
                      'corpus-item-excluded': chunk.is_excluded
                    }"
                    class="card chunk corpus-card my-2"
                    @click="selectChunk(sectionIndex, chunk)"
                >
                  <!-- Заголовок чанка и его чекбокс-->
                  <div class="card-header">
                    <!-- Заголовок чанка -->
                    <div>
                      <span class="font-weight-bold">{{ chunk.number + 1 }}. </span>
                      <span v-if="chunk.title" class="font-weight-bold">{{ chunk.title }}</span>
                      <span v-else class="font-weight-light">Заголовок отсутствует</span>
                    </div>
                    <font-awesome-icon
                        v-if="processingChunks && processingChunks.includes(chunk.id)"
                        fixed-width
                        icon="spinner"
                        spin-pulse
                    />
                    <span>
                      <template v-if="chunk.is_excluded">
                        [Исключён]
                      </template>
                    <b-badge>
                      {{ chunk.id }}
                    </b-badge>
                    </span>
                  </div>
                  <div class="card-body">
                    <!-- Иконка чанка, текст чанка и ссылка-->
                    <chunk-text :chunk="chunk" :source="source"/>
                  </div>
                  <div v-if="chunksStats" class="card-footer">
                    <table class="text-muted fluid w-100">
                      <tr>
                        <td>
                          Проверено
                        </td>
                        <td>
                          <font-awesome-icon icon="list-check"/>
                          Тесты {{ getChunkStat(chunk, 'quiz') }}
                        </td>
                        <td>
                          <font-awesome-icon icon="question-circle"/>
                          Вопрос-ответы {{ getChunkStat(chunk, 'question_answer') }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <!--Кнопки действия-->
        <b-col class="d-flex" cols="2" xl="3" xxl="3">
          <div class="action-buttons corpus-sticky mx-2">

            <!--Кнопка перезагрузки данных-->
            <b-btn-group>
              <b-btn
                  :disabled="corpus.loading"
                  :title="(notificationShown.tooLong || notificationShown.outerChanges) ? 'Требуется обновление' : 'Обновить'"
                  variant="info"
                  @click="reload"
              >
                <template v-if="!corpus.loading">
                  <font-awesome-icon v-if="notificationShown.tooLong || notificationShown.outerChanges"
                                     icon="exclamation"/>
                  <font-awesome-icon fixed-width icon="rotate-right"/>
                </template>
                <b-spinner v-else small type="border"></b-spinner>
                Обновить
              </b-btn>
            </b-btn-group>

            <!--Редактирование одновременно только для одного source - нужно выбрать-->
            <b-form-group class="w-100" label="Документ для просмотра">
              <b-input-group>
                <b-select v-model="selectedSource" :title="selectedSource ? selectedSource.title : ''">
                  <option v-for="option in corpus.data.sources" :value="option" class="w-auto">
                    {{ option.title }}
                  </option>
                </b-select>
              </b-input-group>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button-group vertical>
                  <b-button-group>
                    <l-btn v-bind="chunkButtons.showQuestions" @click="chunkButtons.showQuestions.click"/>
                    <l-btn v-bind="chunkButtons.editChunk" @click="chunkButtons.editChunk.click"/>
                  </b-button-group>

                  <b-button-group>
                    <l-drop-btn right v-bind="chunkButtons.moveChunk"/>
                    <l-btn v-bind="chunkButtons.divideChunk" @click="chunkButtons.divideChunk.click"/>
                    <l-btn v-bind="chunkButtons.joinChunks" @click="chunkButtons.joinChunks.click"/>
                  </b-button-group>

                  <b-button-group>
                    <l-drop-btn v-bind="chunkButtons.markExcluded"/>
                    <l-btn v-bind="chunkButtons.delete" @click="chunkButtons.delete.click"/>
                  </b-button-group>

                  <b-button-group>
                    <l-btn v-bind="chunkButtons.generateChunks" @click="chunkButtons.generateChunks.click"/>
                    <l-btn v-bind="chunkButtons.generateHeadings" @click="chunkButtons.generateHeadings.click"/>
                    <l-drop-btn v-bind="chunkButtons.generateQuest"/>
                  </b-button-group>

                  <b-button-group>
                    <l-btn v-bind="chunkButtons.exportWord" @click="chunkButtons.exportWord.click"/>
                  </b-button-group>

                </b-button-group>
              </b-col>
              <b-col>
                <b-button-group vertical>
                  <b-btn disabled variant="outline-primary">Фоновые задачи</b-btn>

                  <b-btn v-for="key in Object.keys(tasksButtons)" v-if="tasksButtons[key].show !== false"
                         :key="`${key}-bg-btn`"
                         v-bind="tasksButtons[key]"
                         variant="outline-primary" @click="showTasks(key)">
                    {{ tasksButtons[key].label }}
                    <b-badge title="С ошибкой" variant="danger">{{ getTaskStats(key).fail || 0 }}</b-badge>
                    <b-badge title="В процессе" variant="warning">
                      {{ (getTaskStats(key).processing || 0) + (getTaskStats(key).queue || 0) }}
                    </b-badge>
                    <b-badge title="Завершенных" variant="success">{{ getTaskStats(key).complete || 0 }}
                    </b-badge>
                  </b-btn>
                </b-button-group>
              </b-col>
            </b-row>

            <b-form-checkbox v-model="showExcluded" switch>Показывать исключенные</b-form-checkbox>

            <hr/>
            <!--Инфо по выделенному-->
            <div v-if="selectedChunksAsList.length + selectedSections.length">
              <l-btn class="mb-1 d-block" icon="eraser"
                     label="Снять выделение"
                     variant="dark"
                     @click="eraseSelection"
              />

              <template v-if="selectedChunksAsList.length">
                Выбранные чанки ({{ selectedChunksAsList.length }}):

                <ul style="max-height: 150px; overflow-y: scroll">
                  <li v-for="chunk in selectedChunksAsList">
                    {{ chunk.id }}. {{ chunk.title || 'Заголовок отсутствует' }}
                  </li>
                </ul>
              </template>
              <template v-if="selectedSections.length">
                Выбранные разделы ({{ selectedSections.length }}):

                <ul style="max-height: 150px; overflow-y: scroll">
                  <!--suppress HtmlUnknownAttribute -->
                  <li v-for="sectionIndex in selectedSections" :set="section=selectedSource.sections[sectionIndex]">
                    {{ section.number }} {{ section.title }}
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<style scoped>

.scroll-sidebar {
  overflow-y: auto;
  height: calc(100vh - 130px);
}

.action-buttons {
  height: calc(100vh - 69px);
}

.nav-source {
  background-color: #c7dbf1;
  font-weight: bolder;
}

.nav-link.active {
  color: #004d9f;
  font-weight: bolder;
}

.nav-link {
  padding: 0;
}

.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}

.btn-icon {
  cursor: pointer;
}

.btn-icon:hover {
  color: var(--primary);
}

.chunk.selected {
  border: #00022e 1px solid;
  background-color: rgba(154, 163, 166, 0.2);
}

.chunk:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.btn-group-vertical > * {
  margin-bottom: 4px;
}

.btn-group > * {
  margin-right: 4px;
}
</style>

