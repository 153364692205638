import { AiAlias, Prompt } from './main'

const systemContent = ``
const userContent = `Я дам тебе текст, тебе нужно перефразировать этот текст в более логически связанный текст.
Выдавай только итоговый текст без твоих пояснений и без разметки.

Вот этот текст: "{text}".
Подумай и приступай.`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent,
  },
  model: AiAlias.ALT,
})
