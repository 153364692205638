<template>
  <b-container class="methodical" fluid>
    <h3>Методические материалы для авторов и экспертов:</h3>

    <b-row>
      <b-col>
        <b-button class="m-1" variant="text">Инструкции
        </b-button>
        <b-card>
          <b-button class="m-1" variant="text">Для тестовых заданий:
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Shablon_zadaniy_ispr.docx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Шаблон для тестовых заданий_v22.1.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Primer_fayla_ispr.docx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Пример файла тестовых заданий_v22.1.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Instrukciya_po_predostavleniyu_testovyh_zadanij_dlya_akkreditacii.html" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Инструкция по представлению тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Specialnosti_i_razdely_po_specialnostyam.xlsx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Специальности и разделы по специальностям и должностям.xlsx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Instrukciya_eksperta_paketa.html" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Инструкция для эксперта пакета тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Instrukciya_Ekspert_banka.html" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Инструкция для эксперта банка тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <hr/>
          <b-button class="m-1" variant="text">Для практических заданий:
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Pasport_shablon_PSA_030919.pdf" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Шаблон паспорта экзаменационной станции.pdf
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <hr/>
          <b-button class="m-1" variant="text">Для ситуационных заданий (кейсов):
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/SHablon_MK_pustoj_dlya_klinicheskih_specialnoste.docx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Шаблон МК пустой для клинических специальностей.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/SHablon_MK_pustoj_dlya_neklinicheskih_specialnostej.docx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Шаблон МК пустой для неклинических специальностей.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/Primer_zapolneniya_shablona_SZ.docx" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Пример заполнения шаблона МК.docx
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <b-button class="m-1" variant="text">Правила для источников БА:
          </b-button>

          <b-list-group>
            <b-list-group-item>
              <b-button href="https://library.mededtech.ru/files/source-loading-criteria.pdf" style="text-align: start !important;"
                        target="_blank"
                        variant="link">
                Библиотека аккредитации. Критерии загрузки источников
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <b-col>
        <b-button
            class="m-1" variant="text">Видеоуроки
        </b-button>

        <b-list-group>
          <b-list-group-item>
            <b-button v-b-toggle="'Ekspertiza_testovykh_zadaniy_v_banke'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Экспертиза тестовых заданий в банке (для эксперта банка ТЗ)
            </b-button>
            <b-collapse id="Ekspertiza_testovykh_zadaniy_v_banke"
                        @hidden="Ekspertiza_testovykh_zadaniy_v_banke = false"
                        @show="Ekspertiza_testovykh_zadaniy_v_banke = true">
              <b-embed
                  v-if="Ekspertiza_testovykh_zadaniy_v_banke"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Ekspertiza_testovykh_zadaniy_v_banke.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'Ekspertiza_testovykh_zadaniy'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Экспертиза тестовых заданий (для эксперта пакета ТЗ)
            </b-button>
            <b-collapse id="Ekspertiza_testovykh_zadaniy"
                        @hidden="Ekspertiza_testovykh_zadaniy = false"
                        @show="Ekspertiza_testovykh_zadaniy = true">
              <b-embed
                  v-if="Ekspertiza_testovykh_zadaniy"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Ekspertiza_testovykh_zadaniy.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'Videoinstrukcija_dlja_jekspertizy_multikejsov'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Видеоинструкция для экспертизы мультикейсов
            </b-button>
            <b-collapse id="Videoinstrukcija_dlja_jekspertizy_multikejsov"
                        @hidden="Videoinstrukcija_dlja_jekspertizy_multikejsov = false"
                        @show="Videoinstrukcija_dlja_jekspertizy_multikejsov = true">
              <b-embed
                  v-if="Videoinstrukcija_dlja_jekspertizy_multikejsov"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Videoinstrukcija_dlja_jekspertizy_multikejsov.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'author_answer_issagg'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Видеоинструкция для автора мультикейса
            </b-button>
            <b-collapse id="author_answer_issagg"
                        @hidden="author_answer_issagg = false"
                        @show="author_answer_issagg = true">
              <b-embed
                  v-if="author_answer_issagg"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/author_answer_issagg.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'Tochnyye_ssylki_na_otkrytyye_istochniki'"
                      class="m-1" style="text-align: start !important;" variant="link">
              Точные ссылки на открытые источники
            </b-button>
            <b-collapse id="Tochnyye_ssylki_na_otkrytyye_istochniki"
                        @hidden="Tochnyye_ssylki_na_otkrytyye_istochniki = false"
                        @show="Tochnyye_ssylki_na_otkrytyye_istochniki = true">
              <b-embed
                  v-if="Tochnyye_ssylki_na_otkrytyye_istochniki"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Tochnyye_ssylki_na_otkrytyye_istochniki.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'Tochnyye_ssylki_na_zakrytyye_istochniki_full'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Точные ссылки на закрытые источники
            </b-button>
            <b-collapse id="Tochnyye_ssylki_na_zakrytyye_istochniki_full"
                        @hidden="Tochnyye_ssylki_na_zakrytyye_istochniki_full = false"
                        @show="Tochnyye_ssylki_na_zakrytyye_istochniki_full = true">
              <b-embed
                  v-if="Tochnyye_ssylki_na_zakrytyye_istochniki_full"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Tochnyye_ssylki_na_zakrytyye_istochniki_full.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button v-b-toggle="'curator_features_issagg'"
                      class="m-1" style="text-align: start !important;"
                      variant="link">
              Возможности агрегатора замечаний для куратора
            </b-button>
            <b-collapse id="curator_features_issagg"
                        @hidden="curator_features_issagg = false"
                        @show="curator_features_issagg = true">
              <b-embed
                  v-if="curator_features_issagg"
                  allowfullscreen
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/curator_features_issagg.mp4"
                  type="iframe"
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>

        <b-button
            class="m-1" variant="text">Мобильное приложение РЭ
        </b-button>

        <b-list-group>
          <b-list-group-item>
            <b-button href="https://library.mededtech.ru/files/MedEdTech_2.3.30_apkcombo.com.apk" style="text-align: start !important;"
                      target="_blank"
                      variant="link">
              APK файл
            </b-button>
          </b-list-group-item>
          <b-list-group-item>
            <b-button href="https://library.mededtech.ru/files/apk_instruction.docx" style="text-align: start !important;"
                      target="_blank"
                      variant="link">
              Инструкция по установке APK файла
            </b-button>
          </b-list-group-item>
          <b-list-group-item>
            <b-button href="https://library.mededtech.ru/files/privacy.htm" style="text-align: start !important;"
                      target="_blank"
                      variant="link">
              Политика конфиденциальности
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

export default {
  name: "Methodical",
  data: function () {
    return {
      Tochnyye_ssylki_na_zakrytyye_istochniki_full: false,
      Tochnyye_ssylki_na_otkrytyye_istochniki: false,
      Ekspertiza_testovykh_zadaniy_v_banke: false,
      Ekspertiza_testovykh_zadaniy: false,
      Videoinstrukcija_dlja_jekspertizy_multikejsov: false,
      author_answer_issagg: false,
      curator_features_issagg: false
    };
  },
  methods: {},
  mounted: function () {
  }
}
</script>

<style scoped>
.methodical {
  padding-top: 1em;
  padding-left: 2em;
}

.list-group-item {
  padding: 0.2rem 0.4rem;
}

.card-body {
  padding: 0.5rem;
}

.card {
  margin-bottom: 2rem;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
</style>
