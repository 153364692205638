import { AiAlias, Prompt } from './main'
import json_schema from './chunkCreateSchema.json'

const systemContent = `Тебе нужно принять роль преподавателя медицинского университета и ведущего специалиста по анализу и редактированию медицинских текстов.`
const userContent = `Тебе на вход будет поступать медицинский текст в формате json, состоящий из заголовков, обычных абзацев текста 
или списков разной формы (нумерованных, с буллитами или просто через запятую). Тип текста задан в поле "kind": 
значение "paragraph" – это обычный абзац; "list_item" – это список. Также в формате json будет поле "anchor", 
в котором будет находиться ссылка на место в исходном документе. Дополнительно будет вводиться ключевая атрибутивная 
информация для этого текста – название болезни (нозологии), этап лечебно-диагностического процесса (диагностика, лечение, 
профилактика), группа пациентов или лекарств, которая обсуждается в тексте. Атрибутивная информация будет введена ниже после слов: "Вот атрибутивная информация для текста".
Твоя задача – переписать этот текст 
в форме последовательности логически самодостаточных чанков. Требования к таким чанкам:

1) Логическая самодостаточность: каждый чанк должен содержать всю необходимую атрибутивную информацию, чтобы его можно было 
   читать и понимать отдельно от всего рассматриваемого текста, как самостоятельный фрагмент медицинских знаний. То есть нужно 
   добавить в каждый чанк всю необходимую атрибутивную информацию в нужном месте и в нужной форме. Если в чанке уже есть такая 
   информация, то можно ее оставить и не добавлять атрибуты. Но при этом нужно проверить чанк, чтобы выполнялось условие логической 
   самодостаточности. Но при этом нужно проверить чанк, чтобы выполнялось условие логической самодостаточности. 
   Проверяй наличие в каждом чанке Атрибутивной информации. Если в чанке не будет Атрибутивной информации, то получишь ШТРАФ.

2) Чанк не должен иметь ссылки на приложения, таблицы, рисунки, их нужно удалить.

3) В чанке нужно удалить лишние служебные символы и знаки препинания.

4) Размер каждого чанка должен быть: {chunkSize} предложений. Если размер исходных абзацев больше, то необходимо делить такие абзацы 
   на отдельные чанки. При этом для каждого нового чанка также должно выполняться ключевое требование логической 
   самодостаточности (п.1). То есть, в каждый чанк нужно добавить всю необходимую атрибутивную информацию в нужном месте и 
   в нужной форме. Каждый чанк должен смотреться как самостоятельный фрагмент текста, снабженный атрибутивной информацией, иначе получишь штраф. 
   Изменения в текст нужно вносить только в месте внесения атрибутивной информацией.
   Обязательно проверь в новых чанках наличие Атрибутивной информации, если её не будет, то получишь ШТРАФ. 
   Изменения в текст нужно вносить только в месте внесения Атрибутивной информации, остальной текст должен остаться в исходном виде.

5) Если ты видишь список любой формы, то при относительно небольших элементах списка (примерно {smallListLengthTitlePlural}) нужно собрать 
   все элементы списка в один чанк, добавив в этот чанк необходимую атрибутивную информацию. При более длинных элементах списка 
   нужно создавать отдельные чанки.

6) Если в тексте будут встречаться дополнительные заголовки, то нужно проверить – как они относятся к отдельно введенной 
   атрибутивной информации. Если они с ней пересекаются, то их нужно удалить. Если они ее дополняют, то их нужно использовать 
   в чанках как дополнительную атрибутивную информацию. При этом такие заголовки не должны быть самостоятельными чанками, 
   их нужно удалять. При создании чанков тебе нельзя пропускать или выбрасывать ничего из исходного текста. При этом нельзя 
   добавлять от себя дополнительную информацию. При выдаче результатов выдавай только чанки, без своего комментария.

7) Если в процессе обработки обычных параграфов будет происходить деление параграфа на более мелкие чанки, то у каждого чанка 
   должна быть та же ссылка в поле "anchor", что и у исходного параграфа. В противном случае поле "anchor" остается тоже как в 
   исходном параграфе. При создании чанков из списков ("list_item" в поле "kind") в поле "anchor" нужно записать ссылку на 
   заголовок списка.

Итак, приступаем. Вот атрибутивная информация для текста: "{info}"

Вот данные для анализа и переработки: {chunkData}`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent
  },
  model: AiAlias.ALT,
  params: {
    temperature: 0,
    response_format: {
      type: 'json_schema',
      json_schema
    }
  }
})
